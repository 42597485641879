@import "../../vendor_variables";

$side-padding: floor(($grid-gutter-width / 2));

#social_block {
  display: table;
  float: left;
  height: 66px;
  ul{
    display: table-cell;
    vertical-align: middle;
  }
  li{
    display: inline-block;
    margin-left: 8px;
    &:last-child{
      margin-right: 0;
    }
  }
}
